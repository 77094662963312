var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items != null)?_c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.showDrawer()}}}),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.projectType === 'premium' ? 'Manage Subscription' : _vm.$t('dashboard'))+" ")])],1),_c('DashboardMenu',{attrs:{"project-id":_vm.projectId,"project-type":_vm.projectType,"can-update-project":_vm.can(_vm.USER_PERMISSIONS.updateProject)}}),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":{ itemsPerPageOptions: [20] }},scopedSlots:_vm._u([{key:"item.tpl_alias",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getAppIcon(item.tpl_app))+" ")]),_c('v-icon',{staticClass:"mr-3",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.TEMPLATE_TYPE_ICONS[item.tpl_type])+" ")]),_c('TaskLink',{attrs:{"task-id":item.id,"tooltip":item.message,"label":'#' + item.id}}),_c('v-icon',{staticClass:"ml-1 mr-1",attrs:{"small":""}},[_vm._v("mdi-arrow-left")]),_c('router-link',{attrs:{"to":'/project/' + item.project_id +
          '/templates/' + item.template_id}},[_vm._v(_vm._s(item.tpl_alias)+" ")])],1)]}},{key:"item.version",fn:function(ref){
          var item = ref.item;
return [(item.tpl_type !== '')?_c('TaskLink',{staticClass:"ml-2",attrs:{"disabled":item.tpl_type === 'build',"status":item.status,"task-id":item.tpl_type === 'build'
            ? item.id
            : (item.build_task || {}).id,"label":item.tpl_type === 'build'
            ? item.version
            : (item.build_task || {}).version,"tooltip":item.tpl_type === 'build'
            ? item.message
            : (item.build_task || {}).message}}):_c('div',{staticClass:"ml-2"},[_vm._v("—")])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('TaskStatus',{attrs:{"status":item.status}})]}},{key:"item.start",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.start))+" ")]}},{key:"item.end",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMilliseconds")([item.start, item.end]))+" ")]}}],null,false,3508647393)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }